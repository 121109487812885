import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from "styled-components"

import Layout from '../components/Layout'
import SEO from '../components/seo'

const Intro = styled.div`
    color: #333;
    font-size: 1.8rem;
    line-height: 1.3;
    margin-bottom: 6rem;
    padding-right: 4rem;

    @media only screen and (max-width: 768px) {
      margin-bottom: 3rem;
    }
`

const Headline = styled.h1`
    font-size: 5rem;
    margin-bottom: 1rem;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
      margin-top: 1rem;
      font-size: 3rem;
      line-height: 1;
    }
`

const Listing = styled.div`
  padding: 2rem 4rem 2rem 2rem;
  margin-bottom: 1rem;
  background-color: #eee;
  color: 333;

  &:hover {
    background-color: #0D5C00;
    color: #fff;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
`

const StyledLink = styled(Link)`
  color: #333;
  font-size: 2.6rem;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: #fff;
  }
`;

const Summary = styled.p`
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.4;
  margin-top: .5rem;
`

const Anchor = styled.a`
  color: #0D5C00;
  font-weight: 600;
  text-decoration: none;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 60px;
`

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.chapter
    const chapters = data.chapters.edges
    const exercises = data.exercises.edges


    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Frontend Design Curriculum"
          keywords={[`product design`, `designer`, `UX`, `user experience`]}
        />

        <Intro>
            <Headline>Frontend Design</Headline>
            <p>This self-paced course includes a comprehensive series of exercises that will challenge designers to expand their ability to create design solutions with HTML and CSS. This curriculum is a continuation of <Anchor href="/interface">Interface Design</Anchor>.</p>
        </Intro>
        
        <Grid>
            <div>
                {exercises.map(({ node }) => {
                    const chapter = node.frontmatter.chapter || node.fields.slug
                    const sub = node.frontmatter.sub

                    return (
                        <StyledLink to={node.fields.slug}>
                            <Listing key={node.fields.slug}>
                                <h3>
                                    {chapter}
                                </h3>
                                <Summary dangerouslySetInnerHTML={{ __html: sub }} />
                            </Listing>
                        </StyledLink>
                    )
                })}
            </div>
        </Grid>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    chapters: allMarkdownRemark(
      sort: { fields: [frontmatter___courseOrder], order: ASC }
      filter: { frontmatter: { tags: { eq: "frontend" } }
    }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            chapter
            title
            sub
          }
        }
      }
    }
    exercises: allMarkdownRemark(
        sort: { fields: [frontmatter___courseOrder], order: ASC }
        filter: { frontmatter: { tags: { eq: "frontend" } }
      }) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              chapter
              title
              sub
            }
          }
        }
      }
  }
`